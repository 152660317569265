// This Store holds data relating to generic Data Feed items, such as Railcards. This store is more for cache-ability rather than storing user data.
import { defineStore } from 'pinia';
import axios from 'axios';
import _ from 'lodash';

export const useDataFeedsStore = defineStore('dataFeeds', {
  state: ()=>({
    locations: [],
    test_details: [],
    rsp_standards: {},     // This stores the data from loadTestDetails(). It is a object of objects that are keyed by Standard, Standard Version then Test Reference for easy access to the object.
    railcards: [],
    ticket_codes_blacklist: ['1S4','1S3','1S2','1S1','1S0','1SJ','2S4','1S9','2S3','2S2','1S8','2S1','1S7','2S0','2S9','2SJ','2S8','2S7','1D0','1F4','1F3','1F2','1F1','1F0','2D0','2F4','1FB','2F3','1FJ','2F2','1FA','2F1','2F0','1F7','2F9','2F8','2FJ','2F7','SB4','SB3','SB2','SB1','SB0','SB9','SB8','EX4','EX3','EX2','EX1','EX0','E44','E64','E43','E63','EX9','E42','E62','EX8','E41','E61','EX7','E40','E60','AX4','AX3','AX2','AX1','AX0','A44','A64','A63','A43','AX9','A42','A62','AX8','A61','A41','AX7','A40','A60','1FZ','2FZ','1SZ','2SZ','1FH','1FM','1FL','2FH','2FM','2FL','1SH','1SM','1SL','2SH','2SM','2SL',
    ],
  }),
  getters: {
    // Create a getter to access all Scenarios. This is used a lot below and should speed things up since it is a computed property versus directly accessing the state everytime
    //getRailcards: (state) => state.railcards
  },
  actions: {
    // Loads all of the available Railcards into the Store. Returns a Resolved Promise if the load was succesful and a Rejected Promise if an error occurs whilst trying to load.
    loadRailcards() {
      return new Promise((resolve, reject) => {

        // If there are items in the Railcards list already then don't re-run the API call.
        if (!(this.railcards.length > 0)) {

          axios.get(process.env.VUE_APP_API_URL+'railcards/')
          .then(response => {
            if (response.status !== 200) {
              reject("Failed to get Railcards")
            }
            this.railcards = response.data.data.steps.railcards

            resolve('done')
            return
          })
          .catch(error => {
            reject("Failed to load Railcards")
            return
          })
        }

      })
    },
    // Returns a Promised array of all Railcards from the Store. If the Store doesn't contain any Railcards, attempt to load them in from loadRailcards()
    getAllRailcards() {
      return new Promise(async (resolve, reject) => {
        if (typeof this.railcards === "undefined") {
          await this.loadRailcards()
        }
        resolve(this.railcards)
      })
    },

    // Load all locations into the Store
    loadLocations() {
      axios.get(process.env.VUE_APP_API_URL+"library/locations?search=")
      .then(response => {
         this.locations = response.data
      })
    },

    // ==== RSP COMPLIANCE ====
    // Load the details of a RSP Test into the Store.
    loadTestDetails(rsp_standard, rsp_standard_version, test_reference, reload = false) {
      return new Promise((resolve, reject) => {
        // The next 2 If statements create empty Objects if the Standard doesn't exist, this is to prevent undefined variable errors later.
        if (!this.rsp_standards.hasOwnProperty(rsp_standard)) {
          // If this Standard doesn't already exist then create a new blank object
          this.rsp_standards[rsp_standard] = {}
        }
        if (!this.rsp_standards[rsp_standard].hasOwnProperty(rsp_standard_version)) {
          // If the Standard Version for the Standard doesn't exist then create a new blank object
          this.rsp_standards[rsp_standard][rsp_standard_version] = {}
        }

        // Only run the request if the Test Details do not already exist in the Store
        if (_.isEmpty(this.rsp_standards[rsp_standard][rsp_standard_version][test_reference])) {

          axios.get(process.env.VUE_APP_API_URL+'library/rsp-standards/versions/tests?query='+rsp_standard+"_"+rsp_standard_version+"_"+test_reference)
          .then(response => {
            if (response.status !== 200) {
              reject("Failed to get Test Details")
            }
            if (response.data.length === 0) {
              resolve("empty_response")
              // Prevent the code below running. We don't want blank ("undefined") data to be inserted, or code that uses this function will complain about undefined variables.
              return
            }
            // WARNING! The API could return more than one Test if a loose query is set, but the code will only take the first one.


            // Save the first Test object from the API to the Store. Note that at this point, a call to getTestDetails() may have already created a blank Object here, so we will overwrite whatever is here with the API response. If getTestDetails() hasn't made a blank Object then the above 2 code blocks will create the needed blank Objects for the below line to work, or a variable undefined error would occur.
            this.rsp_standards[rsp_standard][rsp_standard_version][test_reference] = response.data[0]

            resolve('done')
          })
          .catch(error => {
            console.log(error)
            reject("Failed to load Test Details")
          })
        } else {
          resolve('already_exists')
        }
      })
    },
    // Get all information on a RSP Test, when given a Standard, Standard Version and Test reference. The Test must already be loaded into the store via loadTestDetails().
    // @return - Empty Object if there is no data, or an object with the details on the Test. Data returned is reactive to the original data in the Store.
    getTestDetails(rsp_standard, rsp_standard_version, test_reference) {
      // These next 3 If statements create blank Objects if the data doesn't already exist. This is to prevent undefined variable errors, as you can't reference an attribute of an object that doesn't exist.
      if (!this.rsp_standards.hasOwnProperty(rsp_standard)) {
        // If this Standard doesn't already exist then create a new blank object
        this.rsp_standards[rsp_standard] = {}
      }
      if (!this.rsp_standards[rsp_standard].hasOwnProperty(rsp_standard_version)) {
        // If the Standard Version for the Standard doesn't exist then create a new blank object
        this.rsp_standards[rsp_standard][rsp_standard_version] = {}
      }
      // Check to see if the Test Reference already exists. If it doesn't create a blank variable.
      if (!this.rsp_standards[rsp_standard][rsp_standard_version].hasOwnProperty(test_reference)) {
        this.rsp_standards[rsp_standard][rsp_standard_version][test_reference] = {}
      }

      // Return the data. The data must be sent like this, and not destructured, to keep reactivity so that if the data is updated by the API all code relying on this function will also update.
      return this.rsp_standards[rsp_standard][rsp_standard_version][test_reference]
    }

  }
})
