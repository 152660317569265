// Creates an empty Store to hold all the data for a Testset.
import { defineStore } from 'pinia';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useDataFeedsStore } from '@/store/data_feeds';

// Template for a single Scenario in its default state
let journey_template = {
  id: 1,
  origin: '',
  origin_group: '',
  destination: '',
  destination_group: '',
  gui_journey_type: 'one_way',
  outbound_departure_datetime: '',
  outbound_arrival_datetime: '',
  return_departure_datetime: '',
  return_arrival_datetime: '',
  railcard: '',
  railcard_quantity: '',
  route_code: '',
  route_via: '',
  route_avoid: '',
  cross_london_marker: '',
  reservation_options: {},
  adult_quantity: '',
  child_quantity: '',
  travel_class: '',
  ticket_type: '',
  ticket_fulfilment_type: ''
}

let scenario_template = {
  id: 1,
  journeys: [
    journey_template
  ]
}

// Template for the entire Testset store default state
let testset_template = {
  testset_meta: {
  },
  testset_scenarios: [JSON.parse(JSON.stringify(scenario_template))]
}

export const useTestsetStore = defineStore('testset', {
  // Set a default state so that elements binding to this don't create an error. Use the template objects above as a starting point.
  state: () => (JSON.parse(JSON.stringify(testset_template))),
  getters: {
    // Create a getter to access all Scenarios. This is used a lot below and should speed things up since it is a computed property versus directly accessing the state everytime
    getScenarios: (state) => state.testset_scenarios
  },
  actions: {
    // Removes all state from the Store and replaces it with the default state.
    resetState() {
      this.testset_meta = {}
      this.testset_scenarios = [JSON.parse(JSON.stringify(scenario_template))]
    },
    // Replaces everything in the store with a Testset from the API, when provided with a Testset ID. Gets the original Testset JSON, not the computed Testset. Returns a Resolved Promise if the load was succesful and a Rejected Promise if an error occurs whilst trying to load the Testset.
    loadTestset(testset_id) {
      return new Promise((resolve, reject) => {

        axios.get(process.env.VUE_APP_API_URL + 'journey/' + testset_id + '?original_json')
          .then(async (response) => {
            if (response.status !== 200) {
              reject("Failed to load Testset")
            }

            let retrieved_testset = response.data

            // Check for old Testsets and convert if needed
            if (this.isTestsetPreJourneySchemaChange(retrieved_testset)) {
              retrieved_testset = this.convertPreJourneySchemaChangeTestset(retrieved_testset)
            }

            // Create the Reservation Options attribute if it does not exist, otherwise the Scenario card will error. Included for backwards-compatibility.
            for (const scenario of retrieved_testset.testset_scenarios) {
              if (!scenario.hasOwnProperty('reservation_options')) {
                scenario.reservation_options = {}
              }

              // The origin appears to be an NLC code not an object. This is an older format - update the value into the new format which is a Station object.
              if (scenario.hasOwnProperty('origin')) {
                if (/^\d+$/.test(scenario.origin) && (scenario.origin.length === 4)) {
                  let station = await axios.get(process.env.VUE_APP_API_URL + 'library/locations/' + scenario.origin)
                  if (station.status === 200) {
                    scenario.origin = station.data
                  }
                }
              }
              if (scenario.hasOwnProperty('destination')) {
                if (/^\d+$/.test(scenario.destination) && (scenario.destination.length === 4)) {
                  let station = await axios.get(process.env.VUE_APP_API_URL + 'library/locations/' + scenario.destination)
                  if (station.status === 200) {
                    scenario.destination = station.data
                  }
                }
              }
              if (scenario.hasOwnProperty('route_via')) {
                if (/^\d+$/.test(scenario.route_via) && (scenario.route_via.length === 4)) {
                  let station = await axios.get(process.env.VUE_APP_API_URL + 'library/locations/' + scenario.route_via)
                  if (station.status === 200) {
                    scenario.route_via = station.data
                  }
                }
              }
              if (scenario.hasOwnProperty('origin_group')) {
                if (/^\d+$/.test(scenario.origin_group) && (scenario.origin_group.length === 4)) {
                  let station = await axios.get(process.env.VUE_APP_API_URL + 'library/locations/' + scenario.origin_group)
                  if (station.status === 200) {
                    scenario.origin_group = station.data
                  }
                }
              }
              if (scenario.hasOwnProperty('destination_group')) {
                if (/^\d+$/.test(scenario.destination_group) && (scenario.destination_group.length === 4)) {
                  let station = await axios.get(process.env.VUE_APP_API_URL + 'library/locations/' + scenario.destination_group)
                  if (station.status === 200) {
                    scenario.destination_group = station.data
                  }
                }
              }

              // Also applies to Mutations...
              if (scenario.hasOwnProperty('mutations')) {
                for (const mutation of scenario.mutations) {
                  if (mutation.hasOwnProperty('data')) {

                    if (mutation.data.hasOwnProperty('origin')) {
                      if (/^\d+$/.test(mutation.data.origin) && (mutation.data.origin.length === 4)) {
                        let station = await axios.get(process.env.VUE_APP_API_URL + 'library/locations/' + mutation.data.origin)
                        if (station.status === 200) {
                          mutation.data.origin = station.data
                        }
                      }
                    }
                    if (mutation.data.hasOwnProperty('destination')) {
                      if (/^\d+$/.test(mutation.data.destination) && (mutation.data.destination.length === 4)) {
                        let station = await axios.get(process.env.VUE_APP_API_URL + 'library/locations/' + mutation.data.destination)
                        if (station.status === 200) {
                          mutation.data.destination = station.data
                        }
                      }
                    }
                    if (mutation.data.hasOwnProperty('route_via')) {
                      if (/^\d+$/.test(mutation.data.route_via) && (mutation.data.route_via.length === 4)) {
                        let station = await axios.get(process.env.VUE_APP_API_URL + 'library/locations/' + mutation.data.route_via)
                        if (station.status === 200) {
                          mutation.data.route_via = station.data
                        }
                      }
                    }
                    if (mutation.data.hasOwnProperty('route_avoid')) {
                      if (/^\d+$/.test(mutation.data.route_avoid) && (mutation.data.route_avoid.length === 4)) {
                        let station = await axios.get(process.env.VUE_APP_API_URL + 'library/locations/' + mutation.data.route_avoid)
                        if (station.status === 200) {
                          mutation.data.route_avoid = station.data
                        }
                      }
                    }
                    if (mutation.data.hasOwnProperty('origin_group')) {
                      if (/^\d+$/.test(mutation.data.origin_group) && (mutation.data.origin_group.length === 4)) {
                        let station = await axios.get(process.env.VUE_APP_API_URL + 'library/locations/' + mutation.data.origin_group)
                        if (station.status === 200) {
                          mutation.data.origin_group = station.data
                        }
                      }
                    }
                    if (mutation.data.hasOwnProperty('destination_group')) {
                      if (/^\d+$/.test(mutation.data.destination_group) && (mutation.data.destination_group.length === 4)) {
                        let station = await axios.get(process.env.VUE_APP_API_URL + 'library/locations/' + mutation.data.destination_group)
                        if (station.status === 200) {
                          mutation.data.destination_group = station.data
                        }
                      }
                    }

                  }
                }
              }

            }

            this.testset_meta = retrieved_testset.testset_meta
            this.testset_scenarios = retrieved_testset.testset_scenarios

            // Split the datetime from the API into a separate date strings and time strings to put into the different input fields
            await this.splitDateTimes()

            // Save the Testset ID so that the Save Testset function can access it later
            this.testset_meta.id = testset_id;

            resolve('done')
          })
          .catch(error => {
            console.log("Error in loadTestset(): ", error)
            reject(error)
          })
      })
    },
    // Updates the currently stored Testset to the API. Returns a Resolved Promise if the update was succesful and a Rejected Promise if an error occurs whilst trying to update the Testset.
    updateTestset() {
      return new Promise(async (resolve, reject) => {
        // This Testset ID attribute must exist to update the Testset, as this is set in loadTestset(). If it doesn't exist then something must be wrong.
        if (this.testset_meta.id < 0) {
          reject("No ID to update Testset.")
        }

        // Call the validateDateTimes() function which checks that a date and time have both been filled out if one has been filled out. If an error is found
        try {
          let dateTimeValidation = await this.validateDateTimes()
        } catch (error) {
          reject(error)
        }

        // Convert sperate dates and times to datetimes
        await this.mergeDateTimes()

        // PUT Testset to API
        axios.put(process.env.VUE_APP_API_URL + 'journey/' + this.testset_meta.id, { testset_meta: this.testset_meta, testset_scenarios: this.testset_scenarios })
          .then(response => {
            if ((response.status !== 204) && (response.status !== 201) && (response.status !== 200)) {
              let errMsg = ""

              // Reject with error message which can be displayed on a Toast message
              if (response.hasOwnProperty('response')) {
                if (response.hasOwnProperty('data')) {
                  if (response.data.hasOwnProperty('error')) {
                    errMsg = response.data.error
                  }
                }
              }

              reject(errMsg)
            }
            resolve('done')
          })
          .catch(error => {
            let errMsg = ""

            // Reject with error message which can be displayed on a Toast message
            if (error.hasOwnProperty('response')) {
              if (error.response.hasOwnProperty('data')) {
                if (error.response.data.hasOwnProperty('error')) {
                  errMsg = error.response.data.error
                }
              }
            }

            reject(errMsg)
          })
      })
    },
    // Creates a Testset through the API using the currently stored Testset. Returns the Testset ID in a Resolved Promise if the creation was succesful and a Rejected Promise if an error occurs whilst trying to create the Testset.
    createTestset() {
      return new Promise(async (resolve, reject) => {

        // Merge dates and times back into a datetime
        await this.mergeDateTimes()

        try {
          // POST Testset from Store to API
          let testset = await axios.post(process.env.VUE_APP_API_URL + 'journey', { testset_meta: this.testset_meta, testset_scenarios: this.testset_scenarios })

          if (testset) {
            if (testset.status !== 201) {
              reject({ "status": "error", "message": testset.response.data })
              return
            } else {
              resolve(testset.data.testset_id)
              return
            }
          } else {
            reject({ "status": "error", "message": "An unknown error occured whilst creating the Testset" })
            return
          }

        } catch (error) {
          console.log("Error in createTestset(): ", error)
          if (error.hasOwnProperty('response')) {
            reject({ "status": "error", "message": error.response.data, "http_response_code": error.response.status })
            return
          } else {
            reject({ "status": "error", "message": error })
            return
          }

        }

      })
    },
    // Merges all separate date attributes and time attributes into one datetime attribute in all Scenarios. This is needed as the API wants a datetime attribute not seperate attributes.
    mergeDateTimes() {
      return new Promise((resolve, reject) => {

        //Loop round each of the scenarios, then change all the datetimes within the journeys element
        for (var i = 0; i < this.testset_scenarios.length; i++) {

          this.testset_scenarios[i].journeys.forEach((element) => {

            if ((element.outbound_departure_date) && (element.outbound_departure_time)) {
              let converted_outbound_date = this.formatDate(element.outbound_departure_date)

              element.outbound_departure_datetime = DateTime.fromISO(converted_outbound_date + 'T' + element.outbound_departure_time.toString()).toUTC().toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'")
            }

            if ((element.outbound_departure_date) && (element.outbound_arrival_time)) {
              element.outbound_arrival_datetime = DateTime.fromISO(element.outbound_departure_date.toString() + 'T' + element.outbound_arrival_time.toString()).toUTC().toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'")
            }

            if ((element.return_departure_date) && (element.return_departure_time)) {

              let converted_return_date = this.formatDate(element.return_departure_date)

              element.return_departure_datetime = DateTime.fromISO(converted_return_date.toString() + 'T' + element.return_departure_time.toString()).toUTC().toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'")
            }

            if ((element.return_arrival_date) && (element.return_arrival_time)) {
              element.return_arrival_datetime = DateTime.fromISO(element.return_arrival_date.toString() + 'T' + element.return_arrival_time.toString()).toUTC().toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'")
            }

            // Combine DateTimes for all Journey Stops
            if (element.journey_stops) {
              if (element.journey_stops.length > 0) {
                element.journey_stops.forEach((stop) => {
                  // Combine outbound depart datetime
                  if ((stop.departure_date) && (stop.departure_time)) {
                    stop.departure_datetime = DateTime.fromISO(stop.departure_date.toString() + 'T' + stop.departure_time.toString()).toUTC().toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'")
                  }

                  // Combine outbound arrival datetime
                  if ((stop.arrival_date) && (stop.arrival_time)) {
                    stop.arrival_datetime = DateTime.fromISO(stop.arrival_date.toString() + 'T' + stop.arrival_time.toString()).toUTC().toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'")
                  }
                })
              }
            }

          })//end of ForEach
        }//end of For
        resolve('done')
      })
    },

    // Return the date now in the format that the Datepicker will accept as its default value initially (as this is different to the value that it vmodels out once you select a date)
    getCurrentDateFormatted() {
      return DateTime.now().toFormat('ccc LLL dd yyyy');
    },

    // Takes a date in the long date format provided by the Datepicker component (e.g. "Sun Jul 30 2023 00:00:00 GMT+0100 (British Summer Time)") and returns it in the format YYYY-MM-DD ready to be sent to the API.
    formatDate(date) {
      let split_date = date.toString().split(' ')

      let day = split_date[2]
      let month = split_date[1]
      let year = split_date[3]

      let converted_outbound_date = year + "-" + month + "-" + day

      converted_outbound_date = DateTime.fromFormat(converted_outbound_date, "yyyy-MMM-dd").toFormat("yyyy-MM-dd")

      return converted_outbound_date
    },

    // Splits all datetime attributes into separate date attributes and time attributes
    splitDateTimes() {
      this.testset_scenarios.forEach((element) => {
        if (element.outbound_departure_datetime) {
          let out_departure_dt = DateTime.fromISO(element.outbound_departure_datetime, { zone: 'utc' })
          element.outbound_departure_date = out_departure_dt.toISODate()
          element.outbound_departure_time = out_departure_dt.toFormat("HH':'mm")
        }

        if (element.outbound_arrival_datetime) {
          let out_arrival_dt = DateTime.fromISO(element.outbound_arrival_datetime, { zone: 'utc' })
          element.outbound_arrival_date = out_arrival_dt.toISODate()
          element.outbound_arrival_time = out_arrival_dt.toFormat("HH':'mm")
        }

        if (element.return_departure_datetime) {
          let return_departure_dt = DateTime.fromISO(element.return_departure_datetime, { zone: 'utc' })
          element.return_departure_date = return_departure_dt.toISODate()
          element.return_departure_time = return_departure_dt.toFormat("HH':'mm")
        }

        if (element.return_arrival_datetime) {
          let return_arrival_dt = DateTime.fromISO(element.return_arrival_datetime, { zone: 'utc' })
          element.return_arrival_date = return_arrival_dt.toISODate()
          element.return_arrival_time = return_arrival_dt.toFormat("HH':'mm")
        }

        // Split out journey stops datetimes
        if (element.journey_stops) {
          if (element.journey_stops.length > 0) {
            element.journey_stops.forEach((stop) => {
              // Outbound Arrival
              if (stop.departure_datetime) {
                let depart_dt = DateTime.fromISO(stop.departure_datetime, { zone: 'utc' })
                stop.departure_date = depart_dt.toISODate()
                stop.departure_time = depart_dt.toFormat("HH':'mm")
              }

              if (stop.arrival_datetime) {
                let arrival_dt = DateTime.fromISO(stop.arrival_datetime, { zone: 'utc' })
                stop.arrival_date = arrival_dt.toISODate()
                stop.arrival_time = arrival_dt.toFormat("HH':'mm")
              }
            })
          }
        }
      })
    },
    // Checks whether a user has entered a date without a time (or vice versa). This is due to the API requiring a datetime string so both a date and time are needed. Returns a rejected Promise with an error message if validation failed, or a resolved Promise if it passed
    validateDateTimes() {
      return new Promise((resolve, reject) => {
        // Function to perform the logic of checking a given date and time.
        /* Logic:
        Date blank and time filled = error
        Date filled and time blank = error
        Date blank and time blank = pass
        Date filled and time filled = pass
    
        If (date is blank or time is blank) AND (date not blank OR Time not blank) THEN error
        */
        function checkDateTime(date, time) {
          // Using truthiness checks which will catch nulls and empty strings. It will be true on 0 however none of the datetime fields should ever have just a 0 in.
          if ((date || time) && (!date || !time)) {
            if (!date) {
              return { valid: false, error_attr: "Date" }
            } else {
              return { valid: false, error_attr: "Time" }
            }

          } else {
            return { valid: true }
          }
        }

        let flag_raised = false
        let error = ""

        // Loop around each Scenario
        this.testset_scenarios.forEach((scenario, index) => {
          let outbound_depart = checkDateTime(scenario.outbound_departure_date, scenario.outbound_departure_time)
          if (outbound_depart.valid === false) {
            flag_raised = true
            error = "Outbound Departure " + outbound_depart.error_attr + " empty in Scenario #" + (index + 1)
          }
          let return_depart = checkDateTime(scenario.return_departure_date, scenario.return_departure_time)
          if (return_depart.valid === false) {
            flag_raised = true
            error = "Return Departure " + return_depart.error_attr + " empty in Scenario #" + (index + 1)
          }
          let return_arrival = checkDateTime(scenario.return_arrival_date, scenario.return_arrival_time)
          if (return_arrival.valid === false) {
            flag_raised = true
            error = "Return Arrival " + return_arrival.error_attr + " empty in Scenario #" + (index + 1)
          }

          if (scenario.hasOwnProperty("journey_stops")) {
            scenario.journey_stops.forEach((stop, stop_index) => {
              let depart = checkDateTime(stop.departure_date, stop.departure_time)
              if (depart.valid === false) {
                flag_raised = true
                error = "Departure " + depart.error_attr + " empty in Scenario #" + (index + 1) + " stop #" + (stop_index + 1)
              }
              let arrival = checkDateTime(stop.arrival_date, stop.arrival_time)
              if (arrival.valid === false) {
                flag_raised = true
                error = "Arrival " + arrival.error_attr + " empty in Scenario #" + (index + 1) + " stop #" + (stop_index + 1)
              }
            })
          }

        })

        if (flag_raised === true) {
          reject(error)
        } else {
          resolve('done')
        }
      })
    },

    // Adds a Scenario to the list of Scenarios
    addJourney(scenario_id) {
      if (typeof scenario_id === "undefined" || scenario_id === null) {
        throw new Error("scenario_id parameter missing in addJourney()")
      }
      let new_journey = JSON.parse(JSON.stringify(journey_template))
      new_journey.id = this.getScenario(scenario_id).journeys.length + 1
      this.getScenario(scenario_id).journeys.push(new_journey)
    },

    // Adds a Scenario to the list of Scenarios
    addScenario() {
      let new_scenario = JSON.parse(JSON.stringify(scenario_template))
      new_scenario.id = this.testset_scenarios.length + 1
      this.testset_scenarios.push(new_scenario)
    },

    // Finds the Scenario Object in the array of Scenario objects when given a scenario_id.
    getScenario(scenario_id) {
      if (typeof scenario_id === "undefined" || scenario_id === null) {
        throw new Error("scenario_id parameter missing in getScenario()")
      }
      return (this.getScenarios.find(obj => obj.id == scenario_id))
    },

    // Finds the single Mutation in an array of Mutations. Will look for Mutation inside Journey if journey_id provided, else it will look inside the Scenario.
    getMutation(scenario_id, journey_id = null, mutation_id) {
      if (typeof scenario_id === "undefined" || scenario_id === null) {
        throw new Error("scenario_id parameter missing in getMutation()")
      }
      if (typeof mutation_id === "undefined" || mutation_id === null) {
        throw new Error("mutation_id parameter missing in getMutation()")
      }
      // If journey id present, look in Journey instead of Scenario
      if (typeof journey_id !== "undefined" && journey_id !== null) {
        return (this.getJourney(scenario_id, journey_id).mutations.find(obj => obj.id == mutation_id))
      } else {
        return (this.getScenario(scenario_id).mutations.find(obj => obj.id == mutation_id))
      }
    },

    // Add a Mutation to the array of Mutations for a Journey in a Scenario. If journey_id provided, will look inside Journey for mutation instead of Scenario.
    addMutation(scenario_id, journey_id) {
      if (typeof scenario_id === "undefined" || scenario_id === null) {
        throw new Error("scenario_id parameter missing in addMutation()")
      }
      if (typeof journey_id !== "undefined" && journey_id !== null) {
        // If the mutations array doesn't exist then create it before trying to push to it
        if (this.getJourney(scenario_id, journey_id).mutations == null) {
          this.getJourney(scenario_id, journey_id).mutations = [];
        }
        this.getJourney(scenario_id, journey_id).mutations.push({ id: this.getJourney(scenario_id, journey_id).mutations.length + 1, data: {} })
      } else {
        // If the mutations array doesn't exist then create it before trying to push to it
        if (this.getScenario(scenario_id).mutations == null) {
          this.getScenario(scenario_id).mutations = [];
        }
        this.getScenario(scenario_id).mutations.push({ id: this.getScenario(scenario_id).mutations.length + 1, data: {} })
      }
    },

    // Remove a Mutation from the array of Mutations for a Scenario
    removeMutation(scenario_id, journey_id = null, mutation_id) {
      if (typeof scenario_id === "undefined" || scenario_id === null) {
        throw new Error("scenario_id parameter missing in removeMutation()")
      }
      if (mutation_id != '') {
        if (typeof journey_id !== "undefined" && journey_id !== null) {
          _.remove(this.getJourney(scenario_id, journey_id).mutations, function (obj) {
            if (obj.id === mutation_id) {
              return true
            }
          });

          // Now that an element has been removed, we need to re-index the IDs in the array (which are static) or the frontend will show a gap in IDs.
          this.getJourney(scenario_id, journey_id).mutations.forEach((mutation, index) => {
            mutation.id = index + 1
          })
        } else {
          _.remove(this.getScenario(scenario_id).mutations, function (obj) {
            if (obj.id === mutation_id) {
              return true
            }
          });

          // Now that an element has been removed, we need to re-index the IDs in the array (which are static) or the frontend will show a gap in IDs.
          this.getScenario(scenario_id).mutations.forEach((mutation, index) => {
            mutation.id = index + 1
          })
        }
      }
    },

    // Get the data of a Mutation Parameter from a single Mutation. If journey_id provided, will look inside Journey for mutation instead of Scenario.
    getMutationParameter(scenario_id, journey_id, mutation_id, parameter) {
      if (typeof scenario_id === "undefined" || scenario_id === null) {
        throw new Error("scenario_id parameter missing in getMutationParameter()")
      }
      if (typeof mutation_id === "undefined" || mutation_id === null) {
        throw new Error("mutation_id parameter missing in getMutationParameter()")
      }
      if (typeof parameter === "undefined" || parameter === null) {
        throw new Error("parameter parameter missing in getMutationParameter()")
      }
      return this.getMutation(scenario_id, journey_id, mutation_id).data[parameter]
    },

    // Add a Mutation Parameter for a given Mutation on a Scenario. If journey_id provided, will look inside Journey for mutation instead of Scenario.
    addMutationParameter(scenario_id, journey_id, mutation_id, parameter) {
      // Find the Scenario by ID then find the Mutation by Mutation ID then insert a Parameter under the found Mutation
      // This must be null and not an empty string, otherwise Vue-Select will think that an empty string is a real value and thus won't show the placeholder text.
      this.getMutation(scenario_id, journey_id, mutation_id).data[parameter] = null
    },

    // Updates a Mutation Parameter with the provided value. If journey_id provided, will look inside Journey for mutation instead of Scenario.
    updateMutationParameter(scenario_id, journey_id, mutation_id, parameter, value) {
      if (typeof scenario_id === "undefined" || scenario_id === null) {
        throw new Error("scenario_id parameter missing in updateMutationParameter()")
      }
      if (typeof mutation_id === "undefined" || mutation_id === null) {
        throw new Error("mutation_id parameter missing in updateMutationParameter()")
      }
      if (typeof parameter === "undefined" || parameter === null) {
        throw new Error("parameter parameter missing in updateMutationParameter()")
      }
      this.getMutation(scenario_id, journey_id, mutation_id).data[parameter] = value
    },

    // Delete a Mutation Parameter for a given Mutation. If journey_id provided, will look inside Journey for mutation instead of Scenario.
    removeMutationParameter(scenario_id, journey_id, mutation_id, parameter) {
      if (typeof scenario_id === "undefined" || scenario_id === null) {
        throw new Error("scenario_id parameter missing in removeMutationParameter()")
      }
      if (typeof mutation_id === "undefined" || mutation_id === null) {
        throw new Error("mutation_id parameter missing in removeMutationParameter()")
      }
      if (typeof parameter === "undefined" || parameter === null) {
        throw new Error("'parameter' parameter missing in removeMutationParameter()")
      }
      delete this.getMutation(scenario_id, journey_id, mutation_id).data[parameter]
    },

    // Remove a Scenario from the Testset when given a Scenario ID
    removeScenario(scenario_id) {
      // Find the Scenario's position in the array
      let scenario_position = this.testset_scenarios.findIndex((element) => {
        if (element.id === scenario_id) {
          return true
        }
      })

      // Remove the Scenario from the Array
      this.testset_scenarios.splice(scenario_position, 1)

      // Now that an element has been removed, we need to re-index the IDs in the array (which are static) or the frontend will show a gap in IDs.
      this.testset_scenarios.forEach((scenario, index) => {
        scenario.id = index + 1
      })
    },

    // Add a Stop to the array of Stops for a Scenario
    addStop(scenario_id) {
      // If the mutations array doesn't exist then create it before trying to push to it
      if (this.getScenario(scenario_id).journey_stops == null) {
        this.getScenario(scenario_id).journey_stops = [];
      }
      this.getScenario(scenario_id).journey_stops.push({
        stop_id: this.getScenario(scenario_id).journey_stops.length + 1,
        departure_date: "",
        departure_time: "",
        arrival_date: "",
        arrival_time: ""
      })
    },

    // Remove a Stop from a Scenario's Stops, given the IDs of the Scenario and Stop
    removeStop(scenario_id, stop_id) {
      try {
        // Find the Scenario's position in the array
        let scenario_position = this.testset_scenarios.findIndex((element) => {
          if (element.id === scenario_id) {
            return true
          }
        })

        // Find the Stop's position in the legs array in the Scenario
        let stops_position = this.testset_scenarios[scenario_position].journey_stops.findIndex((element) => {
          if (element.id === stop_id) {
            return true
          }
        })

        // Remove the Stop from the Scenario from the Array
        this.testset_scenarios[scenario_position].journey_stops.splice(stops_position, 1)
      } catch (error) {
        reject("Failed to remove Stop")
      }
    },
    // Get all the stops for a given Scenario.
    // @return - An array of the journey stops.
    getStops(scenario_id) {
      if (this.getScenario(scenario_id).hasOwnProperty('journey_stops')) {
        return this.getScenario(scenario_id).journey_stops
      } else {
        return []
      }
    },
    // @param journey_id - The ID of the Journey (note - don't use 0 like an array)
    getJourney(scenario_id, journey_id) {
      if (typeof scenario_id === "undefined" || scenario_id === null) {
        throw new Error("scenario_id parameter missing in getJourney()")
      }
      if (typeof journey_id === "undefined" || journey_id === null) {
        throw new Error("journey_id parameter missing in getJourney()")
      }
      if (typeof journey_id !== "number") {
        throw new Error("journey_id parameter must be number")
      }
      if (typeof scenario_id !== "number") {
        throw new Error("scenario_id parameter must be number")
      }

      if (this.getScenario(scenario_id).hasOwnProperty('journeys')) {
        return this.getScenario(scenario_id).journeys[journey_id - 1]
      } else {
        return []
      }
    },

    getJourneys(scenario_id) {
      if (this.getScenario(scenario_id).hasOwnProperty('journeys')) {
        return this.getScenario(scenario_id).journeys
      } else {
        return []
      }
    },

    // Takes a complete Testset object and works out whether it was made pre March 2023 and needs conversion.
    // @return - Boolean, true if Testset is old and needs conversion.
    isTestsetPreJourneySchemaChange(testset_obj) {
      if (testset_obj.testset_scenarios[0].hasOwnProperty('journeys')) {
        return false
      } else {
        return true
      }
    },

    // Takes a complete Testset object that was made pre March 2023 and converts it to the new schema that includes a journeys object, then returns that object.
    convertPreJourneySchemaChangeTestset(old_testset_obj) {
      console.log("old_testset_obj: ", old_testset_obj)
      let new_scenarios = []

      for (const scenario of old_testset_obj.testset_scenarios) {
        console.log("scenario", scenario)
        let new_scenario = {
          collapse_on_gui: false,
          // Keep current Scenario ID
          id: scenario.id,
          journeys: [],
        }
        new_scenario.journeys.push(scenario)

        // The journey ID will be set to the Scenario ID as above, so set it to 1
        new_scenario.id = 1

        new_scenarios.push(new_scenario)
      }

      let converted_testset = {
        testset_meta: old_testset_obj.testset_meta,
        testset_scenarios: new_scenarios
      }

      console.log("converted_testset: ", converted_testset)

      return converted_testset
    },
  }
})
