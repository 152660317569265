// Creates an empty Store to hold all the data for the user and authentication.
import { defineStore } from 'pinia';
import axios from 'axios';

export const useUserStore = defineStore('user', {
  // Set a default state so that elements binding to this don't create an error.
  state: () => ({
    user: {
      role: "",
      id: "",
      firstname: "",
      lastname: "",
      email: "",
    },
    // If the API returned an error whilst getting the user's details, store this so we can dispaly error message.
    get_user_details_error_occured: false,
    loading_user_details: false
  }),
  actions: {

    // Temporary function will always return false as user auth isn't in yet
    isAuthenticated() {
      return false
    },

    // Calls the "authenticated" API endpoint to retrieve the user's logged in status and other metadata
    loadUserDetails() {
      return new Promise(async (resolve, reject) => {
        try {
          this.loading_user_details = true

          // Attempt to get the authenticated API endpoint
          let response = await axios.get(process.env.VUE_APP_API_URL + "users/authenticated", {
            validateStatus: function () {
              return true;
            }
          })

          if (response.status === 401) {
            // User not logged in - redirect to auth
            window.location.replace(process.env.VUE_APP_BASE_URL + "uk/wp-content/themes/conicalsphere/custom-php/authenticate.php");
          } else if (response.status === 200) {
            // User logged in and request successful
            this.user.email = response.data.data.email
            this.user.firstname = response.data.data.firstname
            this.user.lastname = response.data.data.lastname
            this.user.role = response.data.data.role
            this.user.id = response.data.data.user_id

            // Send a message to LogRocket to iredentify this user
            LogRocket.identify(this.user.id, {
              name: this.user.firstname + " " + this.user.lastname,
              email: response.data.data.email,
            });
          } else {
            // The API is not returning a HTTP code that it should so an error has occured - save this variable state so that the UI can notify the user
            console.log("loadUserDetails(): Error occured loading API.")
            this.get_user_details_error_occured = true
          }

          this.loading_user_details = false

          resolve()
          return
        } catch (error) {
          console.log(error.message)
          this.get_user_details_error_occured = true
          this.loading_user_details = false
          reject(error)
          return
        }

      })
    },
  }
})