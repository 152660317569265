<!-- Renders the main navigation menu. -->
<template>

  <div class="menu">
    <!--<div class="item home">
      <router-link to="/home">
        <img src="https://cdn.media.conicalsphere.com/cdn/img/material-icons/action/svg/production/ic_home_48px.svg" alt="Home Logo">
        <span>Home</span>
      </router-link>
    </div>

    <div class="item account">
      <router-link to="/user-settings">
        <img src="https://cdn.media.conicalsphere.com/cdn/img/material-icons/action/svg/production/ic_settings_48px.svg" alt="Account Icon">
        <span>Your Account</span>
      </router-link>
    </div>

    <div class="item logout">
      <a :href="base_url+'uk/wp-login.php?action=logout&redirect_to=https%3A%2F%2Fjourneyright.co.uk%2Fuk%2Frat-welcome'">
        <img src="https://cdn.media.conicalsphere.com/cdn/img/material-icons/action/svg/production/logout_black_24dp.svg" alt="Logout Icon">
        <span>Logout</span>
      </a>
    </div>-->

  </div>

</template>

<script>
export default {
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL
    }
  }
}

</script>

<style scoped>
.menu {
  display: grid;
  grid-template-rows: auto;
  column-gap: 20px;
}
.menu .item img {
  max-width: 25px;
}
.menu .item > a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
  text-decoration: none;
}
.menu .item img, .menu .item > a > span {
  flex-basis: 100%;
}
.menu .item a > span {
  font-weight: 300;
  text-align: center;
  color: #3a3a3a;
  text-decoration: none;
  font-size: 94%;
  margin-top: 1px;
}
.menu .item.home {
  grid-column: 1/1;
}
.menu .item.compliance {
  grid-column: 2/2;
}
.menu .item.people {
  grid-column: 3/3;
}
.menu .item.library {
  grid-column: 4/4;
}
.menu .item.developers {
  grid-column: 5/5;
}
.menu .item.account {
  grid-column: 6/6;
}
.menu .item.logout {
  grid-column: 7/7;
}
</style>
