// Creates an empty Store to hold all the data for a Web TIS order.
import { defineStore } from 'pinia';
import axios from 'axios';
import { useTestsetStore } from "@/store/testset"
import uniqWith from 'lodash/uniqWith'
import orderBy from 'lodash/orderBy'
import first from 'lodash/first'

const error_msg_template = {
  status: "",
  message: "",
  http_response_code: "",
  stack_trace: "",
  tis_api_response: ""
}

export const useWebtisStore = defineStore('webtis', {
  // Set a default state so that elements binding to this don't create an error. Use the template objects above as a starting point.
  state: () => ({
    webtis: {
      // Holds any error messages that have occured when calling the API
      error: error_msg_template,
      show_fullpage_loading: false,
      basket: {
        items: [],
        total_price: 0
      },
      bookingId: undefined,                          // The bookingId for the API that identifies the current order session              
      sessionId: "",                            // The sessionID from Step 4, used for manual card payments
      report_id: null,                          // The current RAT Report ID this is being saved under (Part 1 creates this)
      run_id: null,                             // The current RAT Runner Run ID for this order (Part 1 creates this)
      testset_id: null,
      step_1_response: {},
      part_3_response: {},
      stage_process_is_at: "0",                 // Value to hold where we are in the process of making an order. 0 = Start page, 1 = Journeys page, 2 = Order complete page
      current_journey_id: 1,
      filter_journeys_list_by_inbound: false,
      manual_card_payment_mode: process.env.VUE_APP_ENABLE_MANUAL_CARD_INPUT === 'true' ? true : false,            // Whether to enable a Hosted Payment page to allow the user to input card details
      show_manual_card_payment_overlay: false,         // If true, renders the payment card input overlay in the WebTisBasket component
      this_session_orders: [],                         // Saves any completed orders in the current session for non-logged-in user
      selected_outbound_journey_id: "",               // Used to hold the selected journey when the journey results page is in return outbound mode
    }
  }),
  actions: {
    resetErrorAttribute() {
      this.webtis.error = error_msg_template
    },

    // The journeys page that shows the list of journeys and fares can have multiple states. This function returns a String describing which state the page is in, which is used to change certain functionality e.g. removing fares when showing outbound return journeys. There is a state stored in the Store called gui_journey_type - this is used in this function, and is made by the search widget to tell the other pages what should be displayed - this function is more verbose however.
    getWebtisJourneyMode() {
      const testsetStore = useTestsetStore()

      if (this.webtis.filter_journeys_list_by_inbound) {
        return "return_inbound"
      } else if (testsetStore.getJourney(1, this.webtis.current_journey_id).gui_journey_type === 'return') {
        return "return_outbound"
      } else if (testsetStore.getJourney(1, this.webtis.current_journey_id).gui_journey_type === 'open_return') {
        return "open_return"
      } else {
        return "single"
      }
    },

    // Filters the list of JourneyFares for a given Journey and depending on what journey mode the WebTIS is in (e.g. singles, return outbound, return inbound, open return).
    // @param journey_object - A Journey object, of which the ID will be used.
    getJourneyFaresForJourney(journey_object) {
      let curr_journeykey_fares = []

      if (this.getWebtisJourneyMode() === "return_inbound") {
        // Inbound portion of return journey mode.
        // This will return a list of all combined tickets for the given selected outbound portion of the combined ticket, plus open returns as well.

        for (const fare of this.webtis.step_1_response.journeyFares) {
          // Only add the JourneyFare if:
          // - It's for the current Journey Key that has been fed into this component
          // - It's a return or combined fareType (as we want to show both at this stage)
          // - The outboundKey matches the Journey key of the previously selected outbound journey
          if ((fare.inboundKey === journey_object.key) && (fare.fareType === "combined" || fare.fareType === "return") && (fare.outboundKey === this.webtis.selected_outbound_journey_id)) {
            curr_journeykey_fares.push(fare)
          }
        }

      } else if (this.getWebtisJourneyMode() === "return_outbound") {
        // If it's not an inbound journey for a return journey, it could be the outbound portion of a return journey. 

        for (const fare of this.webtis.step_1_response.journeyFares) {
          // Only add the JourneyFare if:
          // - It's for the current Journey Key that has been fed into this component
          // - The fareType is return or combined
          if ((fare.outboundKey === journey_object.key) && (fare.fareType === "combined" || fare.fareType === "return")) {
            curr_journeykey_fares.push(fare)
          }
        }

      } else if (this.getWebtisJourneyMode() === "open_return") {
        // Open Return mode.

        for (const fare of this.webtis.step_1_response.journeyFares) {
          // Only add the JourneyFare if:
          // - It's for the current Journey Key that has been fed into this component
          // - The fareType is return. This picks actual return tickets.
          if ((fare.outboundKey === journey_object.key) && (fare.fareType === 'return')) {
            curr_journeykey_fares.push(fare)
          }
        }

      } else {
        // Single journey mode.

        for (const fare of this.webtis.step_1_response.journeyFares) {
          // Only add the JourneyFare if:
          // - It's for the current Journey Key that has been fed into this component
          // - The fareType is single
          if ((fare.outboundKey === journey_object.key) && (fare.fareType === 'single')) {
            curr_journeykey_fares.push(fare)
          }
        }
      }

      // Sort the list of JourneyFares by price ascending
      let sorted_journeyfares = orderBy(curr_journeykey_fares, ['totalPrice'], ['asc'])

      console.log("getJourneyFares(): sorted_journeyfares: ", sorted_journeyfares)

      return sorted_journeyfares
    },

    // Find the cheapest fare in a given Journey. Uses getJourneyFares() so the cheapest fare will always match up with the current needed filtering criteria for fares based on the mode single/return.
    // @param journey_object - A Journey object, of which the ID will be used.
    // @return - A Journeyfare object.
    getCheapestJourneyfare(journey_object) {
      let cheapest_fare_value = ""

      // Now sort the filtered journeyfares by price to find the cheapest
      let sorted_journeyfares = orderBy(this.getJourneyFaresForJourney(journey_object), ['totalPrice'], ['asc'])

      /*console.log("----*----")
      console.log(sorted_journeyfares)

      let test = ""

      for (const temp of sorted_journeyfares) {
        test = test + temp.totalPrice + ", "
      }

      console.log(test)*/

      // Pick the first from the list or sorted journeyfares for the cheapest
      cheapest_fare_value = first(sorted_journeyfares)

      if (typeof cheapest_fare_value === 'undefined') {
        cheapest_fare_value = ""
      }

      return cheapest_fare_value
    },

    // This function is run after a succesful purchase is made and adds a few details on the order to a variable in the store. This is then used to render any orders on the orders page that are made in the current session if the user doesn't login when making an order.
    addOrderToSessionOrders() {
      let curr_order = {
        transaction_id: this.webtis.part_3_response['8'].booking.id,
        origin: {
          station_name: this.webtis.basket.items[0].origin
        },
        destination: {
          station_name: this.webtis.basket.items[0].destination
        },
        outbound_datetime: this.webtis.basket.items[0].outbound_departure_time,
        return_datetime: "",
        ticket_type: "",     // This doesnt make sense as its for the whole order which can have different ones in.
        fare: this.webtis.part_3_response['8'].booking.totalPriceFormatted,
        fulfilment: this.webtis.part_3_response['files']
      }

      this.webtis.this_session_orders.push(curr_order)
    },

    // Adds a journey to the basket. This adds in the item on Vue's side (needed for when a guest views their session orders) but also in the API through Part 2 (addJourneyToOrder()) which creates an Order ready to be fulfiled.
    // @param journey_object - The chosen Journey object from the API
    // @param journeyfare_object - The chosen Journeyfare object from the API 
    addJourneyToBasket(journey_object, journeyfare_object) {
      return new Promise(async (resolve, reject) => {
        this.resetErrorAttribute()
        this.webtis.show_fullpage_loading = true

        try {
          await this.addJourneyToOrder(journeyfare_object.furtherDetails)

          this.webtis.basket.items.push({
            origin: this.webtis.step_1_response.places[journey_object.from].name,
            destination: this.webtis.step_1_response.places[journey_object.to].name,
            price: journeyfare_object.totalPrice,
            outbound_departure_time: journey_object.departing.substring(11, 16),
            outbound_arrival_time: journey_object.arriving.substring(11, 16)
          })
        } catch (error) {
          this.showErrorMessage(error)
        }

        this.webtis.show_fullpage_loading = false
        this.webtis.stage_process_is_at = "basket"
        resolve()
        return
      })
    },

    // @param button_location - A String, of either "journey" if the button is located on the Journey, or "fare" if the Button is located on a Journey Fare. This is because different logic runs if the button is located on the Journey which selects the cheapest fare instead of providing the fare.
    // @param journey_object - The chosen Journey object from the API
    // @param journeyfare_object - The chosen Journeyfare object from the API
    onFareButtonClick(button_location, journey_object = false, journeyfare_object = false) {
      if (this.getWebtisJourneyMode() === 'return_outbound') {
        // It's the return outbound journey button, which doesn't select a fare but selects a Journey without adding anything to the basket and then proceeding to the return inbound stage.
        window.scrollTo(0, 0)
        this.webtis.selected_outbound_journey_id = journey_object.key
        // Will initiate a redraw of the journeys list to show inbound journeys (second part of return)
        this.webtis.filter_journeys_list_by_inbound = true

      } else {
        if (button_location === 'journey') {
          // It's the button located on the inbound return journey to immediately add the cheapest fare to the basket.
          // Get the Journeyfare object for the cheapest fare in the provided journey.
          let cheap_journeyfare = this.getCheapestJourneyfare(journey_object)

          this.addJourneyToBasket(journey_object, cheap_journeyfare)

        } else if (button_location === 'fare') {
          // It's a specific fare so add it to basket
          this.addJourneyToBasket(journey_object, journeyfare_object)

        } else {
          console.log("Error in onFareButtonClick(): button_location parameter invalid.")
        }
      }
    },

    // There are fare buttons on the Journey as well as on the JourneyFares, these often need to show different values depending on where they are located and the state of the journey results page. Using this function saves having template v-if's to change the buttons text. Returns a String with the correct value.
    getFareButtonText(button_location) {
      if (button_location === 'journey') {
        if (this.getWebtisJourneyMode() === 'return_outbound') {
          return "Select"
        } else {
          return "Book"
        }
      } else if (button_location === 'fare') {
        return "Book"
      } else {
        console.log("Error in getFareButtonText(): button_location parameter invalid.")
      }
    },

    // Call Part 2 of the WebTis process, which adds a Journey to an order to get a bookingId. This shouldn't be called directly from the frontend, use addJourneyToBasket() otherwise the front-end won't update.
    addJourneyToOrder(furtherDetails) {
      return new Promise(async (resolve, reject) => {
        let ratrunner = null

        try {
          let journey_count = this.webtis.basket.items.length + 1

          ratrunner = await axios.post(process.env.VUE_APP_API_URL + 'run', {
            "data": {
              "testset_id": this.webtis.testset_id,
              "tis_release_id": "26",
              "run_id": this.webtis.run_id,
              "report_id": this.webtis.report_id,
              "part": {
                "id": 2,
                "data": {
                  "journey_id": journey_count,
                  "bookingId": this.webtis.bookingId,
                  "further_details": furtherDetails
                }
              }
            }
          })

          // Save the booking ID to the store
          this.webtis.bookingId = ratrunner.data.data.steps["3"].bookingId

          // Save the sessionId to the store for use with manual card payments
          this.webtis.sessionId = ratrunner.data.data.steps["4"].sessionId

          resolve()
        } catch (error) {
          console.log("Error in addJourneyToOrder(): ", error)
          reject(error)
        }
      })
    },

    // Decide which action to take when pressing the Buy button.
    buyButtonClick() {
      return new Promise(async (resolve, reject) => {
        try {

          if (this.webtis.manual_card_payment_mode === true) {
            // Manual card payment mode is on - show manual card payment overlay. That component will carry on the purchase execution flow.
            // If the card input overlay is closed, Vue doesn't know about it, and we want it to reload if the users closes it and opens it to try an action again. Set the overlay to false then true again if it was already true triggers the component to re-init the payment overlay.
            if (this.webtis.show_manual_card_payment_overlay === true) {
              this.webtis.show_manual_card_payment_overlay = false
              // Wait for the watcher in the component to detect the change
              await new Promise(r => setTimeout(r, 400));
            }
            // This tells the component to bring up the card input overlay
            this.webtis.show_manual_card_payment_overlay = true
          } else {
            // Payment should be performed by backend automatically - continue with API request Part 3.
            this.payForOrderWithRatRunner()
          }

          resolve()
          return
        } catch (error) {
          console.log("Error in buyButtonClick(): ", error)
          reject()
          return
        }
      })
    },

    // Pay and complete the Order (Part 3) by sending a request to the API. Changes the data object depending on whether the card details have already been entered in the frontend.
    payForOrderWithRatRunner() {
      return new Promise(async (resolve, reject) => {
        let ratrunner = null
        try {
          console.log("Running payForOrderWithRatRunner()...")
          this.resetErrorAttribute()
          this.webtis.show_fullpage_loading = true

          let part_3_data_payload = {
            "journey_id": 1,
            "bookingId": this.webtis.bookingId
          }

          if (this.webtis.manual_card_payment_mode === true) {
            // Manual card payment mode is on - let Part 3 in the API know this so that it doesn't perform a payment again.
            part_3_data_payload.manual_card_payment = true
            // Close the card payment overlay component
            this.webtis.show_manual_card_payment_overlay = false
          }

          ratrunner = await axios.post(process.env.VUE_APP_API_URL + 'run', {
            "data": {
              "testset_id": this.webtis.testset_id,
              "tis_release_id": "26",
              "run_id": this.webtis.run_id,
              "report_id": this.webtis.report_id,
              "part": {
                "id": 3,
                "data": part_3_data_payload
              }
            }
          })

          this.webtis.show_fullpage_loading = false
          this.webtis.part_3_response = ratrunner.data.data.steps

          this.webtis.stage_process_is_at = "2"

          // Add order to session orders so a non-logged in user can view the order
          this.addOrderToSessionOrders()

          resolve()
        } catch (error) {
          this.webtis.show_fullpage_loading = false
          this.showErrorMessage(error)
          console.log("Error in payForOrderWithRatRunner(): ", error)
          reject(error)
          return
        }
      })
    },

    // Takes an error message from an Axios API call and puts it to a variable that triggers an error message box to display
    showErrorMessage(error) {
      if (error.hasOwnProperty('response')) {
        // Error object is an Axios API call object
        if (error.response.hasOwnProperty("status")) {
          if (error.response.status === 502) {
            // Known error has occured
            this.webtis.error = error.response.data
            if (this.webtis.error.tis_api_response) {
              this.webtis.error.tis_api_response = JSON.parse(this.webtis.error.tis_api_response)
            }
          } else if (error.response.status === 500) {
            // Unknown server side error occured
            this.webtis.error.message = error
          }
        }
      } else if ((error.hasOwnProperty("status")) && (error.hasOwnProperty("message"))) {
        // Error object is formatted with a status and message attribute
        this.webtis.error.status = error.status
        this.webtis.error.message = error.message
        if (error.hasOwnProperty("http_response_code")) {
          this.webtis.error.http_response_code = error.http_response_code
        }
      } else {
        // An unknow error has occured - just assign the error to the ErrorMessage
        this.webtis.error.message = error
      }

      window.scrollTo(0, 0)
    }
  }
})