import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'web_tis',
    component: () => import(/* webpackChunkName: "web_tis" */ '../views/WebTis.vue'),
    meta: {
      title: 'Buy Train Tickets | GreenRail'
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/orders/view-orders.vue'),
    meta: {
      title: 'My Orders | GreenRail'
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'web_tis_terms',
    component: () => import(/* webpackChunkName: "web_tis_terms" */ '../views/WebTisTerms.vue'),
    meta: {
      title: 'Terms and Conditions | GreenRail'
    }
  },

  // If nothing matches above, then catch here, which is our 404 page.
  {
    path: '/:pathMatch(.*)*',
    name: 'not_found',
    component: () => import(/* webpackChunkName: "not_found" */ '@/components/NotFound.vue'),
    meta: {
      title: 'Page not found | GreenRail'
    }
  },
]

let create_web_history_param = '/'
if (process.env.VUE_APP_DEPLOY_ENVIRONMENT === 'localdev') {
  create_web_history_param = '/rail.green/'
}

const router = createRouter({
  history: createWebHistory(create_web_history_param),
  routes,
  mode: 'history',
  base: '/',
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

//ROUTER GUARD THAT IS NEEDED FOR PAGE TITLES. This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  // Check
  //if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
  next();
});

export default router
