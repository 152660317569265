<!-- Displays the footer for the page. -->
<template>
  <div class="footer" v-if="webtisStore.webtis.stage_process_is_at !== 'basket'">
    <a href="https://www.nationalrail.co.uk/times_fares/conditions-of-travel.aspx" target="_blank">National Rail
      Conditions of Travel</a>
  </div>
</template>

<script>
import { useWebtisStore } from "@/store/webtis";
import { mapStores } from "pinia"; // Need pinia's mapper functions as we are not using Composition API

export default {
  computed: {
    // Make the Testset Store available to this component.
    ...mapStores( useWebtisStore),
  }
};

</script>

<style scoped>
.footer {
  margin-top: 20px;
  height: 65px;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  background-color: #fbfbfb;
  align-items: center;
  border: none;
  margin: 0;
  background: rgb(249 249 249);
}
</style>
