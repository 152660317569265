<!-- Displays the top-level Header for the page. -->
<template>
  <div class="header">
    <!--<div style="color: green; font-size: 220%; font-weight: 400;">GreenRail</div>-->
    <img src="https://cdn.media.conicalsphere.com/cdn/img/logo/GreenRailLogo.svg" class="logo" />
    <Menu />
  </div>

  <!-- Homepage shortcut buttons -->
  <!--<template v-if="this.$route.name === 'home'">
    <div class="shortcut_buttons">
      <div class="compliance_review" @click="this.$router.push({ name: 'create_compliance_review_page' })">
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
          width="24px" fill="rgb(51, 103, 214)">
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <g>
              <path
                d="M5,5h2v3h10V5h2v5h2V5c0-1.1-0.9-2-2-2h-4.18C14.4,1.84,13.3,1,12,1S9.6,1.84,9.18,3H5C3.9,3,3,3.9,3,5v14 c0,1.1,0.9,2,2,2h6v-2H5V5z M12,3c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,3,12,3z" />
              <polygon points="21,11.5 15.51,17 12.5,14 11,15.5 15.51,20 22.5,13" />
            </g>
          </g>
        </svg>
        <span>Create Compliance Review</span>
      </div>

      <div class="testset" @click="this.$router.push({ name: 'testsets' })">
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
          width="24px" fill="rgb(51, 103, 214)">
          <g>
            <path d="M0,0h24v24H0V0z" fill="none" />
          </g>
          <g>
            <g>
              <path d="M4,6H2v14c0,1.1,0.9,2,2,2h14v-2H4V6z" />
              <path
                d="M20,2H8C6.9,2,6,2.9,6,4v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M14.01,15 c-0.59,0-1.05-0.47-1.05-1.05c0-0.59,0.47-1.04,1.05-1.04c0.59,0,1.04,0.45,1.04,1.04C15.04,14.53,14.6,15,14.01,15z M16.51,8.83 c-0.63,0.93-1.23,1.21-1.56,1.81c-0.13,0.24-0.18,0.4-0.18,1.18h-1.52c0-0.41-0.06-1.08,0.26-1.65c0.41-0.73,1.18-1.16,1.63-1.8 c0.48-0.68,0.21-1.94-1.14-1.94c-0.88,0-1.32,0.67-1.5,1.23l-1.37-0.57C11.51,5.96,12.52,5,13.99,5c1.23,0,2.08,0.56,2.51,1.26 C16.87,6.87,17.08,7.99,16.51,8.83z" />
            </g>
          </g>
        </svg>
        <span>Create Testset</span>
      </div>

    </div>
  </template>-->

  <!-- Homepage shortcut buttons -->
  <!--<template v-if="this.$route.name === 'web_tis'">
    <div class="shortcut_buttons">
      <router-link to="/orders">
        <span>My Orders</span>
      </router-link>
    </div>
  </template>-->
</template>

<script>
import Menu from "@/components/Menu.vue";
import axios from "axios";
//Import Store used to hold the Filter
import { useUserStore } from "@/store/user.js"
import { mapStores } from "pinia"; // Need pinia's mapper functions as we are not using Composition API
//Core UI Elements
import { CDropdown } from "@coreui/vue-pro";
import { CDropdownMenu } from "@coreui/vue-pro";
import { CDropdownToggle } from "@coreui/vue-pro";
import { CDropdownItem } from "@coreui/vue-pro";

export default {
  components: {
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    Menu,
  },
  data() {
    return {
      organisations: [],
      filter: {},
    };
  },
  methods: {
    getOrganisations() {
      axios
        .get(process.env.VUE_APP_API_URL + "organisations")
        .then((response) => {
          this.organisations = response.data.organisations;

          // Add a default value to the beginning of the array so that the user can select 'All Organisations'
          this.organisations.unshift({
            id: null,
            fullname: "All Organisations",
            owner: null,
          });
        })
        .catch((error) => {
          console.log("Error in getOrganisations(): ", error);
        });
    },
    filterOrganisation(organisation) {
      this.organisation_filterStore.organisation = organisation; //Save the object to the Filter Store
    },
  },
  computed: {
    // Make the Store available to this component.
    ...mapStores(useUserStore),
  },
  created() {
    if (this.userStore.user.role === 'reseller') {
      this.getOrganisations();
    }
  },
};
</script>

<style lang="css" scoped src="@coreui/coreui-pro/dist/css/coreui.min.css"></style>
<style scoped>
.header {
  position: absolute;
  top: 0;
  border: 0;
  height: 65px;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 9px 8px 14px;
  background-color: transparent;
  align-items: center;
}

.header .logo {
  max-height: 40%;
  padding-left: 10px;
}

.back_button_wrapper {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin: 14px 0 -20px 10px;
  font-weight: 300;
  column-gap: 3px;
  text-transform: uppercase;
  font-size: 93%;
  color: #000;
  cursor: pointer;
  opacity: 0.8;

  margin-top: 16px;
  margin-bottom: -24px;
}

.back_button {
  max-width: 26px;
}

.shortcut_buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 29px;
  border-bottom: 1px solid #d5d5d5;
  padding-right: 2%;
  padding-bottom: 1%;
  color: rgb(51, 103, 214);
  margin-top: 17px;
}

.btn {
  --cui-btn-active-bg: #3367dd;
  --cui-btn-bg: #3367dd;
  --cui-btn-hover-bg: #3367dd;
}

.shortcut_buttons .compliance_review {
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
}

.shortcut_buttons .testset {
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
}

@media (min-width: 1200px) {
.header .logo {
    max-height: 100%;
    padding-left: 0px;
    margin: auto;
    padding-top: 15px;
}
}
</style>
