<template>
  <PageHeader />
  <div class="main_content_container" :class="this.$route.name">
    <router-view />
  </div>
  <PageFooter />
</template>

<script>
import PageHeader from "@/components/PageHeader.vue"
import PageFooter from "@/components/PageFooter.vue"

export default {
  components: {
    PageHeader,
    PageFooter
  },
};

</script>

<style>
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

.main_content_container {

}
</style>
