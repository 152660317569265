import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css";
import { vfmPlugin } from 'vue-final-modal'
import { createPinia } from 'pinia'
import ReadMore from 'vue-read-more';
import '@coreui/coreui-pro/dist/css/coreui.min.css'

const pinia = createPinia()
const app = createApp(App)
app.use(router).use(ReadMore).use(pinia).use(vfmPlugin).use(Toast, { transition: "Vue-Toastification__fade" }).mount('#app')

pinia.use(({ store }) => {
  store.$route = app.config.globalProperties.$route ;
}); 
